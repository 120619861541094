import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LatQuotesIntro'

// markup
const LatQuotes_e = () => {
  return (
   <Layout >
      <Head title='Latin Quotes and Expressions - E'/>
     <h1>LATIN QUOTES AND EXPRESSIONS</h1>
     <h2>E</h2>
     <LatQuotesIntro />
    
     <p><b>E contrario</b> - From a contrary position</p>
<p><b>E pluribus unum</b> - From many, one (motto of the USA)</p>
<p><b>E re nata</b> - As circumstances dictate</p>
<p><b>E vestigio</b> - From where one stands</p>
<p><b>Ecce homo</b> - Behold the man</p>
<p><b>Ecce signum</b> - Behold the proof</p>
<p><b>Editio princeps</b> - First printed edition</p>
<p><b>Ego et rex meus</b> - I and my King</p>
<p><b>Ego me bene habeo</b> - With me all is well. (last words) (Burrus)</p>
<p><b>Ego nolo caesar esse</b> - I don't want to be Caesar. (Florus)</p>
<p><b>Ego spem pretio non emo</b> - I do not purchase hope for a price. (I do not buy a pig in a poke.)</p>
<p><b>Ego</b> - Consciousness of one's own identity</p>
<p><b>Eheu fugaces labuntur anni</b> - Alas, the fleeting years slip by. (Horace)</p>
<p><b>Eiusdem generis</b> - Of the same kind</p>
<p><b>Emeritus</b> - Honorary; by merit</p>
<p><b>Emitte lucem et veritatem</b> - Send out light and truth</p>
<p><b>Ense et aratro</b> - With sword and plow</p>
<p><b>Eo ipso</b> - By that very act</p>
<p><b>Eo nomine</b> - Under that name</p>
<p><b>Epistula non erubescit</b> - A letter doesn't blush. (Cicero)</p>
<p><b>Eram quod es, eris quod sum</b> - I was what you are, you will be what I am. (grave inscription)</p>
<p><b>Ergo bibamus</b> - Therefore, let us drink</p>
<p><b>Ergo</b> - Therefore</p>
<p><b>Errare humanum est</b> - To err is human. / It is human to err. (Seneca)</p>
<p><b>Errata</b> - A list of errors (in a book)</p>
<p><b>Erratum (errata)</b> - Error (errors)</p>
<p><b>Esse est percipi</b> - Being is perception. (It is a standard metaphysical) (Mauser)</p>
<p><b>Esse quam videri</b> - To be, rather than to seem (state motto of North Carolina)</p>
<p><b>Est autem fides credere quod nondum vides; cuius fidei merces est videre quod credis</b> - Faith is to believe what you do not see; the reward of this faith is to see what you believe. (St. Augustine)</p>
<p><b>Est deus in nobis</b> - There is a god inside us</p>
<p><b>Est modus in rebus</b> - There is a middle ground in things. (Horace)</p>
<p><b>Est queadam fiere voluptas</b> - There is a certain pleasure in weeping. (Ovid)</p>
<p><b>Esto perpetue</b> - May you last for ever</p>
<p><b>Et alii/aliae</b> - Other persons/things</p>
<p><b>Et cetera/etcetera (etc.)</b> - And the rest</p>
<p><b>Et in arcadia ego</b> - I, also, am in Arcadia</p>
<p><b>Et sequens (et seq.)</b> - And the following</p>
<p><b>Et sequentes (et seq. Or seqq.)</b> - And those that follow</p>
<p><b>Et sic de ceteris</b> - And so to of the rest</p>
<p><b>Et tu, Brute</b> - And you, Brutus</p>
<p><b>Et uxor (abbreviated et ux.)</b> - And wife</p>
<p><b>Etiam capillus unus habet umbram</b> - Even one hair has a shadow. (Publilius Syrus)</p>
<p><b>Eventus stultorum magister</b> - Events are the teacher of the stupid persons. Stupid people learn by experience, bright people calculate what to do</p>
<p><b>Ex abrupto</b> - Without preparation</p>
<p><b>Ex abundancia cordis, os loquitor</b> - From the abundance of the heart the mouth speaks</p>
<p><b>Ex animo</b> - From the heart (sincerely)</p>
<p><b>Ex ante</b> - Before the event, beforehand. (economics: based on prior assumptions)</p>
<p><b>Ex cathedra</b> - From the chair. With authority (without argumentation)</p>
<p><b>Ex cearulo</b> - Out of the blue</p>
<p><b>Ex curia</b> - Out of court</p>
<p><b>Ex dolo</b> - Intentionally</p>
<p><b>Ex gratia</b> - Purely as a favour</p>
<p><b>Ex hypothesi</b> - From the hypothesis. (i.e. The one under consideration)</p>
<p><b>Ex libris</b> - From the Library (of)</p>
<p><b>Ex luna, scientia</b> - From the moon, knowledge. (motto of Apollo 13)</p>
<p><b>Ex mea sententia</b> - In my opinion</p>
<p><b>Ex more</b> - According to custom</p>
<p><b>Ex nilhilo nihil fit</b> - Nothing comes from nothing</p>
<p><b>Ex officio</b> - By virtue of his office</p>
<p><b>Ex opere operato</b> - By the work having been worked</p>
<p><b>Ex parte</b> - By only one party to a dispute in the absence of the other</p>
<p><b>Ex post facto</b> - After the fact, or Retrospectively</p>
<p><b>Ex proprio motu</b> - Voluntarily</p>
<p><b>Ex silentio</b> - From silence. (from lack of contrary evidence)</p>
<p><b>Ex tempore</b> - Off the cuff, without preparation</p>
<p><b>Ex uno disce omnes</b> - From one person learn all persons. (From one we can judge the rest)</p>
<p><b>Ex vi termini</b> - By definition</p>
<p><b>Ex voto</b> - According to one's vow</p>
<p><b>Ex</b> - Out of</p>
<p><b>Excelsior</b> - Ever upward. (state motto of New York)</p>
<p><b>Exceptio probat regulam de rebus non exceptis</b> - An exception establishes the rule as to things not excepted</p>
<p><b>Exceptis excipiendis</b> - Excepting what is to be excepted</p>
<p><b>Excitabat fluctus in simpulo</b> - He was stirring up billows in a ladle. (He was raising a tempest in a teapot) (Cicero)</p>
<p><b>Excusatio non petita, accusatio manifesta</b> - He who excuses himself, accuses himself (qui s'excuse, s'accuse)</p>
<p><b>Exeat</b> - Permission for a temporary absence</p>
<p><b>Exegi monumentum aere perennius</b> - I have erected a monument more lasting than bronze. (Horace)</p>
<p><b>Exempli gratia (e.g)</b> - For the sake of example</p>
<p><b>Exeunt omnes</b> - All go out. (A common stage direction in plays)</p>
<p><b>Exeunt</b> - They go out</p>
<p><b>Exit</b> - He/she goes out</p>
<p><b>Exitus acta probat</b> - The outcome proves the deeds. (the end justifies the means) (Ovid)</p>
<p><b>Experientia docet stultos</b> - Experience teaches fools</p>
<p><b>Experientia docet</b> - Experience is the best teacher</p>
<p><b>Expressio unius est exclusio alterius</b> - The mention of one thing may exclude others</p>
<p><b>Extempore</b> - Without premeditation</p>
<p><b>Extinctus amabitur idem</b> - The same [hated] man will be loved after he's dead. How quickly we forget. (Horace)</p>
<p><b>Extra ecclesiam nulla salus</b> - Outside the Church [there is] no salvation. (A phrase of much disputed significance in Roman Catholic theology)</p>
<p><b>Extra territorium jus dicenti impune non paretur</b> - The judgment (or the authority) of one who is exceeding his territorial jurisdiction is disobeyed with impunity</p>

   </Layout>
  )
}

export default LatQuotes_e
